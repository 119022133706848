<template>
	<div>
		<hero :title="item.title" :subtitle="item.shortDescription">
			<!-- <span class="icon-save save" :class="item.saved ? 'text-white' : 'text-grey'"></span><span class="text-white pl-2">{{item.saved ? 'Saved' : 'Save'}}</span> -->
		</hero>
		<b-container>
			<b-row>
				<b-col md="8" class="mt-3">
					<div class="icons mb-5">
						<img src="/assets/img/icons/calendar.svg" alt="" class="pr-2">{{item.time | formatTime}} 
						<img src="/assets/img/icons/fax.svg" alt="" class="pl-3 pr-2">{{item.skill}} 
						<img src="/assets/img/icons/pie-chart.svg" alt="" class="pl-3 pr-2">English 
					</div>
					<div class="op-heading">This course covers the classification and pricing of air cargo including chargeable weight, freight rates and airline rate negotiations.</div>
					<heading class="mt-5">What you'll learn</heading>
					<p class="mb-5" v-html="item.description"></p>
					<heading>Course Content</heading>
					<accordian id="subjectinfo" :items="subjectinfo" group="subjectinfo" class="mb-5"></accordian>
					<heading>Assessment</heading>
					<p class="mb-5">Multiple Choice Assessment available for Certificate of Completion.</p>
					<heading>Course Price</heading>
					<p class="mb-5">Free for CargoWise One users and CargoWise Partners; only accessible from the WiseTech Academy link on your MyAccount page.</p>
				</b-col>
				<b-col md="4">
					<img :src="item.image" alt="" class="w-100 mb-3">
					<span class="pl-2 pr-4 bg-light h2 mb-3 d-inline-block">${{item.price}}</span>
					<b-button class="mb-3 d-block w-75">Buy Now</b-button>
					<a href="#" class="mb-5 d-inline-block font-size-xs">Discounted prices for CargoWise and FTA members</a>
					<h4 class="font-weight-bold text-dark">This course includes:</h4>
					<ul class="mb-5 has-arrows">
						<li>100% Online</li>
						<li>Flexible Deadlines</li>
						<li>Beginner Level</li>
						<li>English</li>
						<li>24 hours on demand video</li>
						<li>Full lifetime access</li>
						<li>Access on mobile and TV</li>
					</ul>
					<train-your-team />
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import { TrainYourTeam } from "@/components/ui";

export default {
	name: 'CourseDetail',
	props: ['slug'],
	components: {
		'train-your-team': TrainYourTeam
	},
	computed: {
		item() {
			return this.$store.state.courses.find(el => el.slug == this.slug)
		},
		subjectinfo() {
			return [
				{
					title: '1. Air Cargo  classification rates and charges', content: 'Acting on behalf of their clients (usually importers), a licensed customs broker manages the border clearance process for cargo. This includes ensuring compliance with the laws of different countries, as well as advising clients on a range of technical matters such as biosecurity and importing permits.'
				},
				{
					title: '2. Flight diagnostics and cargo handling', content: 'Acting on behalf of their clients (usually importers), a licensed customs broker manages the border clearance process for cargo. This includes ensuring compliance with the laws of different countries, as well as advising clients on a range of technical matters such as biosecurity and importing permits.'
				},
				{
					title: '3. Other things', content: 'Acting on behalf of their clients (usually importers), a licensed customs broker manages the border clearance process for cargo. This includes ensuring compliance with the laws of different countries, as well as advising clients on a range of technical matters such as biosecurity and importing permits.'
				},
			]
		}
	}
}
</script>

<style lang="scss">
.save{
	vertical-align: middle;
	+ .text-white{
		line-height: 1.5rem;
	}
}
.icons img{
	transform: scale(.9);
	vertical-align: text-top;
}
hr.fat-blue{
	border-bottom: 3px solid $primary;
}
ul.has-arrows{
	padding-left: 1rem;
	li{
		list-style: none;
		position: relative;
		&::before {
			position: absolute;
			left: -1rem;
			content: '>'
		}
	}
}
</style>